import React from "react";
import { useFamilyFriendlyFlag } from "hooks/useFeatureFlags";
import ClassSearch from "app/components/Algolia/ClassSearch";
import { AlgoliaIndexes } from "../Algolia/types";

interface Props {
  instructorName: string;
  hitsPerPage?: number;
  selectedFrom?: string;
}

export function InstructorClasses({
  instructorName,
  selectedFrom = "InstructorClasses",
  hitsPerPage = 3,
}: Props) {
  const isFamilyFeatureEnabled = useFamilyFriendlyFlag();

  if (!instructorName) {
    return null;
  }

  return (
    <ClassSearch
      isFamilyFeatureEnabled={isFamilyFeatureEnabled}
      refinements={{
        instructor_name: instructorName,
      }}
      hitsPerPage={hitsPerPage}
      sortBy={AlgoliaIndexes.classesByPublishDateDesc}
      selectedFrom={selectedFrom}
      preventUrlUpdates
    />
  );
}
