import Div from "app/components/Div";
import Flex from "app/components/Flex";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { InstructorClasses } from "app/components/InstructorClasses";
import { PageTitle } from "app/components/RouteComponents";
import env from "helpers/env";
import PropTypes from "prop-types";
import React from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import { firebaseConnect, getVal } from "react-redux-firebase";
import { compose } from "redux";
import styled from "styled-components";
import InstructorBody from "./Body";
import InstructorHeader from "./Header";

const Banner = styled.div`
  background: ${({ backgroundImage }) =>
      `url(${env("PUBLIC_ASSET_PATH")}/backgrounds/${backgroundImage})`}
    no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: ${({ theme }) => theme.colors.white};
  height: 400px;
`;

const DarkOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
`;

const InstructorProfile = ({ instructor, requested }) =>
  !requested || !instructor ? (
    <LoaderCentered />
  ) : (
    <Div pb={32}>
      <Helmet>
        <title>{instructor.name} | Online Dance Classes and Tutorials</title>
        <meta
          name="description"
          content={`Learn to dance from ${instructor.name}. Dance credits: ${instructor.credits}`}
        />
        <meta
          property="og:url"
          content={`https://${env("PUBLIC_DOMAIN")}/library/instructors/${
            instructor.slug
          }`}
        />
        <meta
          property="og:title"
          content={`${instructor.name} | Online Dance Classes and Tutorials`}
        />
      </Helmet>

      <Banner
        backgroundImage={
          instructor.backgroundImageUrl ||
          "bg-instructor-profile-default-logo.jpg"
        }
      >
        <DarkOverlay />
      </Banner>
      <Flex justifyContent="center">
        <Div width="100%" maxWidth="1280px">
          <InstructorHeader instructor={instructor} />
          <InstructorBody instructor={instructor} />
          <React.Fragment>
            <Div px="3">
              <PageTitle>Recent Classes</PageTitle>
            </Div>
            <Div px={isMobile ? 0 : 3}>
              <InstructorClasses
                instructorName={instructor?.name}
                selectedFrom="RecentClasses"
                hitsPerPage="8"
              />
            </Div>
          </React.Fragment>
        </Div>
      </Flex>
    </Div>
  );

const mapStateToProps = ({ firebase }, props) => ({
  instructor: getVal(firebase, `data/instructors/${props.match.params.slug}`),
  requested: firebase.requested[`instructors/${props.match.params.slug}`],
});

InstructorProfile.defaultProps = {
  instructor: null,
  requested: false,
};

InstructorProfile.propTypes = {
  instructor: PropTypes.shape({}),
  requested: PropTypes.bool,
};

export default compose(
  connect(mapStateToProps),
  firebaseConnect(props => [`instructors/${props.match.params.slug}`])
)(InstructorProfile);
