import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Facebook,
  Instagram,
  Location,
  Twitter,
  Youtube,
} from "app/components/Icon";
import env from "helpers/env";

const AVATAR_HEIGHT = 160;

const Wrapper = styled.div`
  display: flex;
  margin-top: -${AVATAR_HEIGHT / 2}px;
  padding: 0 ${({ theme }) => theme.space[3]}px;

  ${({ theme }) => theme.mediaQueries.ltmd} {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
`;

const Name = styled.h1`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mediaQueries.ltmd} {
    color: ${({ theme }) => theme.colors.darkGrey};
  }
`;

const Avatar = styled.img`
  height: ${AVATAR_HEIGHT}px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
`;

const LinkIcon = styled.a`
  margin-right: 30px;
  display: grid;
  place-items: center;

  ${({ theme }) => theme.mediaQueries.ltmd} {
    margin-right: 15px;
    margin-left: 15px;
  }
  :hover {
    opacity: 0.8;
  }
`;

const Icon = styled.div`
  height: 25px;
  width: 25px;
  color: ${({ theme }) => theme.colors.darkGrey};
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mediaQueries.ltmd} {
    flex-direction: column;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mediaQueries.ltmd} {
    margin: 10px 0;
  }
`;

const LocationSpan = styled.span`
  font-size: 14px;
  margin-left: 4px;
`;

const InstructorHeader = ({ instructor }) => (
  <Wrapper>
    <Avatar
      src={`${env("PUBLIC_ASSET_PATH")}/choreographers/${
        instructor.slug
      }.png?auto=compress`}
    />
    <InfoWrapper>
      <Name>{instructor.name}</Name>
      <Links>
        {instructor.social && (
          <LinkWrapper>
            {instructor.social.youtube && (
              <LinkIcon href={instructor.social.youtube} target="blank_">
                <Icon as={Youtube} />
              </LinkIcon>
            )}
            {instructor.social.facebook && (
              <LinkIcon href={instructor.social.facebook} target="blank_">
                <Icon as={Facebook} />
              </LinkIcon>
            )}
            {instructor.social.instagram && (
              <LinkIcon href={instructor.social.instagram} target="blank_">
                <Icon as={Instagram} />
              </LinkIcon>
            )}
            {instructor.social.twitter && (
              <LinkIcon href={instructor.social.twitter} target="blank_">
                <Icon as={Twitter} />
              </LinkIcon>
            )}
          </LinkWrapper>
        )}
        {instructor.location && (
          <LinkWrapper>
            <Icon as={Location} />
            <LocationSpan>{instructor.location}</LocationSpan>
          </LinkWrapper>
        )}
      </Links>
    </InfoWrapper>
  </Wrapper>
);

InstructorHeader.propTypes = {
  instructor: PropTypes.shape({}).isRequired,
};
export default InstructorHeader;
